import React from "react";
import Header from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./Homepage.css";
import Search from "../../Components/Common/Search/Search";
import SliderOne from "./MultiSliders/SliderOne";
import CategorySlider from "./MultiSliders/CategorySlider";
import FeaturesPropertySlider from "./MultiSliders/FeaturesPropertySlider";
import VisionarySlider from "./MultiSliders/VisionarySlider";
import PrestigiousSlider from "./MultiSliders/PrestigiousSlider";
import TopSelectionsSlider from "./MultiSliders/TopSelectionsSlider";
import PremierSlider from "./MultiSliders/PremierSlider";
import LatestListing from "./MultiSliders/LatestListing";
import HotPicksSlider from "./MultiSliders/HotPicksSlider";
import { FaArrowRightLong } from "react-icons/fa6";
import PropertyServices from "./MultiSliders/PropertyServicesSlider";
import IndianCitiesSlider from "./MultiSliders/IndianCitiesSlider";
import FeatureCollectionsSlider from "./MultiSliders/FeatureCollectionsSlider";
import SpotLightSlider from "./MultiSliders/SpotLightSlider";
const Homepage = () => {
  return (
    <>
      <div className="homepage">
        <Header />
        {/* section one Banner start */}
        <div className="section-one">
          <div className="container d-flex flex-column gap-0">
            <div className="big-heading">Looking For A Home?</div>
            <div className="big-para">
              Find a variety of properties that suit you very easily, forget all
              difficulties in finding a residence for you
            </div>
            <div className="w-100" style={{ position: "relative" }}>
              <Search />
            </div>
          </div>
        </div>
        {/* main container start */}
        <div className="container main-cont">
          {/* section two Category slider start */}
          <div className="section-two">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Our Services <span></span>
              </h4>
            </div>
            <div>
              <SliderOne />
            </div>
          </div>
          {/* section Featured Collections start  */}
          <div className="section-ninth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                {" "}
                Featured Collections <br></br>
                {/* <span className="fs-4"> Popular properties by our expert agents</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Collections</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeatureCollectionsSlider />
            </div>
          </div>
          {/* section subcategory start */}
          {/* <div className="section-three">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>Categories</h4>
               <div className="see-all-btn ">
                <span className="">See all</span> <FaArrowRightLong  fontSize={'13'}   />
              </div>
            </div>
            <div>
              <CategorySlider />
            </div>
          </div> */}
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Featured Properties <span></span>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-fifth" style={{ position: "relative" }}>
            <div className="spotlight-heading">
              <h4>Signature Spots</h4>
              <p>Find your best place to live with us. </p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>
          {/* slider  */}

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Popular Owner Properties
                {/* : <span>Hyderabad's Leading Developers</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Premium Projects <span></span>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Projects</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-fifth">
            <div className="spotlight-heading">
              <h4>Signature Spots</h4>
              <p>Find your best place to live with us. </p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Projects in Focus
                {/* : <span>Hyderabad's Leading Developers</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Projects</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Visionary Ventures <span></span>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-fifth">
            <div className="spotlight-heading">
              <h4>Signature Developers</h4>
              <p>Find your best place to live with us. </p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Leading Landmark
                {/* : <span>Hyderabad's Leading Developers</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">See all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                SFTAREA Premier Properties <span></span>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-fifth">
            <div className="spotlight-heading">
              <h4>Upcoming Projects</h4>
              <p>Find your best place to live with us. </p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Latest Listings
                {/* : <span>Hyderabad's Leading Developers</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">See all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          {/* <div className="section-eightth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                In Spotlight: <span> Find your best places</span>
              </h4>
            </div>
            <div>
              <Spotlight url={"./Images/Spotlightimage.png"} />
            </div>
          </div> */}
          {/* section Prime Projects start */}
          {/* <div className="section-sixth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Prime Projects:{" "}
                <span>Top Projects and Developers Unveiled</span>
              </h4>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div> */}
          {/* section  Prestigious Properties start */}
          {/* <div className="section-ninth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                {" "}
                Prestigious Properties:{" "}
                <span> Hyderabad's Leading Projects</span>
              </h4>
            </div>
            <div>
              <PrestigiousSlider />
            </div>
          </div> */}
          {/* section Top Selections start */}
          {/* <div className="section-tenth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Top Selections: <span>Highly Recommended Projects </span>
              </h4>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div> */}
          {/* section Hot Picks start */}
          {/* <div className="section-eleventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Hot Picks: <span>Hyderabad's Premier Developments </span>
              </h4>
            </div>
            <div>
              <HotPicksSlider />
            </div>
          </div> */}
          {/* section Top Selections start */}
          {/* <div className="section-twelth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Top Selections: <span>Highly Recommended Projects </span>
              </h4>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div> */}
          {/* section SFTAREA Premier Properties start */}
          {/* <div className="section-thirteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>SFTAREA Premier Properties</h4>
            </div>
            <div>
              <PremierSlider />
            </div>
          </div> */}
          {/* <div className="section-fourteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                In Spotlight: <span>Find your best places </span>
              </h4>
            </div>
            <div>
              <Spotlight url={"./Images/Spotlightimage.png"} />
            </div>
          </div> */}
          {/* <div className="section-fifteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Latest Listings: <span>Discover the Newest Properties</span>
              </h4>
            </div>
            <div>
              <LatestListing />
            </div>
          </div> */}

          {/* Investment Opportunit */}

          <div className="section-sixteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Commercial Investments
                <div className="mt-2 fs-5">
                  <span>Profitable Properties or Opportunity Investments:</span>
                  <div>
                    <span>Profitable for Investment</span>
                  </div>
                </div>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Opportunities</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div>

          {/* section In Spotlight start */}
          <div className="section-seventeen">
            <div className="spotlight-heading">
              <h4>Signature Spots</h4>
              <p>Find your best place to live with us. </p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* Income Generating */}
          <div className="section-seventeen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Income-Generating Properties:<span>Investment Options</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">See all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div>
          {/* section realState */}
          <div className="section-seventeen">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">Real Estate in Popular Indian Cities</h4>
              <div className="see-all-btn ">
                <span className="">See all</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <IndianCitiesSlider />
            </div>
          </div>
        </div>
        <div className="download-container">
          <div className="container">
            <div className="row d-flex align-items-center ">
              <div className="col-7">
                <div className="download-left-side  ">
                  <div className="donwload_text_main_div">
                    <div className="download_text">
                      Download the SFT Area App:
                    </div>
                    <p className="download_text_pra">
                      Where Dream Homes are Discovered
                    </p>
                  </div>

                  <div className="d-flex gap-4  flex-column">
                    <div className="d-flex gap-4">
                      <div className="download_card_select_div px-3 py-3">
                        <h6> Stay Ahead</h6>
                        <p className="download_card_select_pra mb-0">
                          {" "}
                          Instant alerts for listings that match your dream home
                          criteria.
                        </p>
                      </div>
                      <div className="download_card_select_div px-3 py-3">
                        <h6> Search Smart</h6>
                        <p className="download_card_select_pra mb-0">
                          Intuitive filters and maps put you in control of the
                          hunt.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex gap-4">
                        <div className="download_card_select_div px-3 py-3">
                          <h6> Explore in Detail</h6>
                          <p className="download_card_select_pra mb-0">
                            {" "}
                            High-definition photos and in-depth guides to every
                            property.
                          </p>
                        </div>
                        <div className="download_card_select_div px-3 py-3">
                          <h6> Connect on the Go</h6>
                          <p className="download_card_select_pra mb-0">
                            One-tap contact with agents to secure your viewings.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gap-2">
                      <img src="/Images/playstore.png" alt="" />
                      <img src="/Images/appstoreIcon.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="download-right-side ">
                  <img src={"/Images/handimage.png"} alt="" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container property-main_container ">
          <div className="row">
            <div className="property_text_heading h3">Property Services</div>
            <p className=" property_text_pra ps-3">
              Donec porttitor euismod dignissim. Nullam a lacinia ipsum, nec
              dignissim purus.
            </p>
          </div>
          <PropertyServices />
        </div> */}

        <Footer />
      </div>
    </>
  );
};

export default Homepage;
