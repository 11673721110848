import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';

const IndianCitiesSlider = () => {
           // for slider  
const sliderEightProductService = [
    {
      name1: "Delhi / NCR",
      name2: "Mumbai",
      url1: "./Images/realState1.png",
      url2: "./Images/realState4.png",
      group_name1:"155,000 + Properties",
      group_name2:"155,000 + Properties",
   

    },
    {
        name1: "Bangalore",
        name2: "Hyderabad",
        url1: "./Images/realState2.png",
        url2: "./Images/realState4.png",
        group_name1:"155,000 + Properties",
        group_name2:"155,000 + Properties",
     
      },
      {
        name1: "Pune",
        name2: "Kolkata",
        url1: "./Images/realState3.png",
        url2: "./Images/realState5.png",
        group_name1:"155,000 + Properties",
        group_name2:"155,000 + Properties",
     
      
  
      },
      {
        name1: "Chennai",
        name2: "Ahmedabad",
        url1: "./Images/realState3.png",
        url2: "./Images/realState5.png",
        group_name1:"155,000 + Properties",
        group_name2:"155,000 + Properties",
     
  
      },
     
      
  ];
  
  const sliderEightresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderEightproductTemplate = (product) => {
    return (
     <div className='d-flex flex-column gap-4'>
         <div className="Hot-Picks-slider-card gap-0">
        
     
        <div className="rounded-image">
          <img src={product.url1} width={'120px'} height={''} alt="" />
        </div>
     <div className='content-div-hot  mt-4'>
     <div className='h5-heading'><h5>{product.name1}</h5></div>
        <div className='group-name mt-1'>{product.group_name1} </div>
       
     </div>
      
    
    </div>
    <div className="Hot-Picks-slider-card gap-0">
        
     
        <div className="rounded-image">
          <img src={product.url2}  width={'120px'} height={''} alt="" />
        </div>
     <div className='content-div-hot mt-4'>
     <div className='h5-heading'><h5>{product.name2}</h5></div>
        <div className='group-name mt-1'>{product.group_name2}</div>
       
     </div>
      
    
    </div>
     </div>
    );
  };
  return (
    <>
                        <Slider ProductService={sliderEightProductService} responsiveOptions={sliderEightresponsiveOptions} productTemplate={sliderEightproductTemplate}/>
 
    </>
  )
}

export default IndianCitiesSlider
