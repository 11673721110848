import React, { useEffect, useState } from "react";
import "./Search.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { Slider } from "primereact/slider";
import { Checkbox } from "primereact/checkbox";
import {
  MdKeyboardVoice,
  MdMyLocation,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { FaAngleDown } from "react-icons/fa";
import { PiSlidersDuotone } from "react-icons/pi";
import { CgSearch } from "react-icons/cg";
const Search = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedBaths, setSelectedBaths] = useState(null);
  const [selectedBeds, setSelectedBeds] = useState(null);
  const [selectedStates, setSelectedStates] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountry2, setSelectedCountry2] = useState(null);
  const [value, setValue] = useState("");
  const [valueRange, setValueRange] = useState([30000,160000]);
  const [valueSizeRange, setValueSizeRange] = useState([0,5000]);

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholders = [
    '"Noida"',
    '"3 BHK sale in Mumbai"',
    '"Hyderabad"',
    '"Farm house in punjab below 1 cr"',
    '"Flates for rent in sector 77 Noida"',
    '"PG in sector 74 Noida"',
  ];

  const cities = [
    { name: "Property Type", code: "NY" },
    { name: "Apartments", code: "NY" },
    { name: "Bungalow", code: "NY" },
    { name: "HomeStay", code: "NY" },
    { name: "Office", code: "NY" },
    { name: "Penthouse", code: "NY" },
    { name: "Smarthome", code: "NY" },
    { name: "Villa", code: "NY" },
  
  ];
  const beds = [
    { name: "1", code: "NY" },
    { name: "2", code: "NY" },
    { name: "3", code: "NY" },
    { name: "4", code: "NY" },
  
  
  ];
  const room = [
    { name: "1", code: "NY" },
    { name: "2", code: "NY" },
    { name: "3", code: "NY" },
    { name: "4", code: "NY" },
  
  
  ];
  const baths = [
    { name: "1", code: "NY" },
    { name: "2", code: "NY" },
    { name: "3", code: "NY" },
    { name: "4", code: "NY" },
  
  
  ];

  const states = [
    { name: " Province/States", code: "NY" },
    { name: "Alabama", code: "NY" },
    { name: "Alaska", code: "NY" },
    { name: "California", code: "NY" },
    { name: "Florida", code: "NY" },
    { name: "Hawai", code: "NY" },
    { name: "Others", code: "NY" },
  
  ];
  

  const countries = [
    { name: 'Neighborhoods', code: 'AU' },
    { name: 'Austin', code: 'BR' },
    { name: 'California', code: 'CN' },
    { name: 'Chicago', code: 'EG' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'India', code: 'IN' },
    { name: 'Japan', code: 'JP' },
    { name: 'Spain', code: 'ES' },
    { name: 'United States', code: 'US' }
];

  const countries2 = [
    { name: 'Neighborhoods', code: 'AU' },
    { name: 'Austin', code: 'BR' },
    { name: 'California', code: 'CN' },
    { name: 'Chicago', code: 'EG' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'India', code: 'IN' },
    { name: 'Japan', code: 'JP' },
    { name: 'Spain', code: 'ES' },
    { name: 'United States', code: 'US' }
];

  const [ingredients, setIngredients] = useState([]);

  const onIngredientsChange = (e) => {
      let _ingredients = [...ingredients];

      if (e.checked)
          _ingredients.push(e.value);
      else
          _ingredients.splice(_ingredients.indexOf(e.value), 1);

      setIngredients(_ingredients);
  }
  const formSubmitHandle = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);


  const selectedCountryTemplate = (option, props) => {
      if (option) {
          return (
              <div className="flex align-items-center">
                  <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                  <div>{option.name}</div>
              </div>
          );
      }

      return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
      return (
          <div className="flex align-items-center">
              <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
              <div>{option.name}</div>
          </div>
      );
  };
  return (
    <>
  <div className="main-search-container">
  <div className="search-container">
        <ul className="nav nav-tabs border-0" 
        // style={{backgroundColor:activeTab===6?"#7a1ea3":"" ,transition:"all 0s ease-in-out"}}
        >
          <li className="nav-item">
            <a
              className={`nav-link  ${activeTab == 1 && "active"}`}
              onClick={() => setActiveTab(1)}
              aria-current="page"
              href="#"
            >
              Buy
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link  ${activeTab == 2 && "active"}`}
              onClick={() => setActiveTab(2)}
              href="#"
            >
              Rent
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab == 3 && "active"}`}
              onClick={() => setActiveTab(3)}
              href="#"
            >
               Commercial
             
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab == 4 && "active"}`}
              onClick={() => setActiveTab(4)}
              href="#"
            >
                Plots / Land
            </a>
          </li>
          <li className="nav-item special-nav-items">
            <a
              className={`nav-link ${activeTab == 5 && "active"}`}
              onClick={() => setActiveTab(5)}
              href="#"
            >
             New Launch Projects
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab == 6 && "active"}`}
              onClick={() => setActiveTab(6)}
              href="#"
            >
            PG / CO-Living
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={`nav-link ${activeTab == 7 && "active"}`}
              onClick={() => setActiveTab(7)}
              href="#"
            >
              Projects
            </a>
          </li> */}
        </ul>
        <div className="search-bar">
          <form onSubmit={formSubmitHandle} className="">
            <div className="location-search d-flex  gap-3 align-items-center">
              <div className="input-text-box">
                <InputText
                  value={value}
                  placeholder="Enter Keyword..."
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="dropdown-box d-flex align-items-center">
                <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  placeholder="Property Types"
                  className="w-100 md:w-14rem"
                />
              </div>
              <div className="dropdown-box d-flex align-items-center">
              
                <Dropdown value={selectedCountry}  filterInputAutoFocus={false} filterIcon={" "} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Neighborhoods" 
                filter  className="w-100 md:w-14rem" />
              </div>

              <button className="extand-btn" onClick={()=>setOpen(!open)}>
                <PiSlidersDuotone size={22}/>
              </button>
              <button className="submit-search d-flex">
                Search Now
                <CgSearch size={19} />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="another-search-container" style={{height:open?'290px':'0px' ,transition:"all 0.4s ease-in-out"}}>
       <div className="" style={{padding:"30px 20px"}}>
       <div className="multi-drops d-flex gap-3 justify-content-between">
          <div className="state-drop-box">
          <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedStates}
                  onChange={(e) => setSelectedStates(e.value)}
                  options={states}
                  optionLabel="name"
                  placeholder="Province/States"
                  className="w-100 md:w-14rem"
                />
          </div>
          <div className="state-drop-box">   <Dropdown value={selectedCountry2}  filterInputAutoFocus={false} filterIcon={" "} onChange={(e) => setSelectedCountry2(e.value)} options={countries2} optionLabel="name" placeholder="Neighborhoods" 
                filter  className="w-100 md:w-14rem" /></div>
          <div className="state-drop-box">    <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedRoom}
                  onChange={(e) => setSelectedRoom(e.value)}
                  options={room}
                  optionLabel="name"
                  placeholder="Rooms"
                  className="w-100 md:w-14rem"
                /></div>
          <div className="state-drop-box">
          <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedBaths}
                  onChange={(e) => setSelectedBaths(e.value)}
                  options={baths}
                  optionLabel="name"
                  placeholder="Baths: Any"
                  className="w-100 md:w-14rem"
                />
          </div>
        
        </div>
        <div className="mt-3 d-flex gap-4">
        <div className="state-drop-box">
          <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedBeds}
                  onChange={(e) => setSelectedBeds(e.value)}
                  options={beds}
                  optionLabel="name"
                  placeholder="Beds: Any"
                  className="w-100 md:w-14rem"
                />
          </div>
          <div className="amount-rage-box"> 
          <div className="label-range mb-3"> From ${valueRange[0]} - ${valueRange[1]}</div>
           <div className="ps-2 pe-3"><Slider  value={valueRange} onChange={(e) => {
            setValueRange(e.value)
          
            }} min={30000} max={160000} className="w-100"   range/> </div></div>
          <div className="amount-rage-box"> 
          <div className="label-range mb-3"> Size {valueSizeRange[0]} - {valueSizeRange[1]}</div>
          <div className=" ps-2 pe-3">
          <Slider  value={valueSizeRange} onChange={(e) => {
            setValueSizeRange(e.value)
          
            }} min={0} max={5000} className="w-100"   range/> 
          </div>
          </div>
         
       
        </div>
        <div id="horizontal-line"></div>
        <div className="row">
          <div className="col-md-3 d-flex flex-column gap-2">
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient1" name="Air conditioning" value="Air conditioning" onChange={onIngredientsChange} checked={ingredients.includes('Air conditioning')} />
                <label htmlFor="ingredient1" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Air conditioning</label>
            </div>
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient2" name="Built in robes" value="Built in robes" onChange={onIngredientsChange} checked={ingredients.includes('Built in robes')} />
                <label htmlFor="ingredient2" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Built in robes</label>
            </div>
          
            </div>
         
          <div className="col-md-3 d-flex flex-column gap-2">
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient5" name="Alarm system" value="Alarm system" onChange={onIngredientsChange} checked={ingredients.includes('Alarm system')} />
                <label htmlFor="ingredient5" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Alarm system</label>
            </div>
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient6" name="Dishwasher" value="Dishwasher" onChange={onIngredientsChange} checked={ingredients.includes('Dishwasher')} />
                <label htmlFor="ingredient6" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Dishwasher</label>
            </div>
         
            </div>
         
          <div className="col-md-3 d-flex flex-column gap-2">
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient9" name="Balcony" value="Balcony" onChange={onIngredientsChange} checked={ingredients.includes('Balcony')} />
                <label htmlFor="ingredient9" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Balcony</label>
            </div>
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient10" name="Ensuite" value="Ensuite" onChange={onIngredientsChange} checked={ingredients.includes('Ensuite')} />
                <label htmlFor="ingredient10" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Ensuite</label>
            </div>
         
            </div>
         
          <div className="col-md-3 d-flex flex-column gap-2">
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient13" name="Broadband" value="Broadband" onChange={onIngredientsChange} checked={ingredients.includes('Broadband')} />
                <label htmlFor="ingredient13" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Broadband</label>
            </div>
           <div className="flex align-items-center">
                <Checkbox inputId="ingredient14" name="Fully fenced" value="Fully fenced" onChange={onIngredientsChange} checked={ingredients.includes('Fully fenced')} />
                <label htmlFor="ingredient14" style={{fontSize:"14px", fontWeight:"400", color:"#2b2b2b"}} className="ml-2 ps-2">Fully fenced</label>
            </div>
          
            </div>
         
        </div>
       </div>
      </div>

  </div>
     
    </>
  );
};

export default Search;
