import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { LuMoveRight } from 'react-icons/lu';
import { FaArrowRight } from 'react-icons/fa';

const FeatureCollectionsSlider = () => {
     // for slider ninth
  const sliderNinthProductService = [
    {
      name: "Owner Properties",
      url: "./Images/home.png",
      number:"23797"
    },
   
    {
      name: "Projects",
      url: "./Images/Homeinteriors.png",
      number:"616"
    },
   
    {
      name: "Ready to move-in",
      url: "./Images/HomeLoan.png",
      number:"16545"
    },
   
    {
      name: "Budget Homes",
      url: "./Images/investment.png",
      number:"1216"
    },
   
    {
        name: "Budget Homes",
        url: "./Images/investment.png",
        number:"1216"
      },
     
   
  ];

  const sliderNinthresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 4,
      numScroll: 1,
    },
    
    {
      breakpoint: "967px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderNinthproductTemplate = (product) => {
    return (
      <div className="features-collection-slider-card">
        <div className="rounded-image-div" style={{backgroundImage: `url(${product.url})`}}>
          {/* <img src={product.url} width={'100%'} alt="" /> */}
          <div className="content-div">
           
             <div>
                <div className='h5-heading'><h5>{product.number}</h5></div>
             <div className="">
                <h6>{product.name}</h6>
              </div>
            <div className='explore-btn mt-3'>
                Explore <FaArrowRight fontSize={"12"}/>
            </div>
            
             </div>
          
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
       <Slider

        ProductService={sliderNinthProductService}
        responsiveOptions={sliderNinthresponsiveOptions}
        productTemplate={sliderNinthproductTemplate}
      />
    </>
  )
}

export default FeatureCollectionsSlider
