import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';

const SliderOne = () => {
    // for slider one
const sliderOneProductService = [
    {
      name: "Interior Design",
      url: "./Images/interior.png",
    },
    {
      name: "Renew Contractors",
      url: "./Images/Contractors.png",
    },
    {
      name: "Loan Hub",
      url: "./Images/loan.png",
    },
    {
      name: "Legal Expert",
      url:  "./Images/legal.png",
    },
    {
      name: "Property Management",
      url:  "./Images/property.png",
    },
    {
      name: "Valuation Advisors",
      url:  "./Images/valuation.png",
    },
    {
      name: "Design & Architect",
      url:  "./Images/Design.png",
    },
    {
        name: "Market Mastery",
        url:  "./Images/market.png",
      },
    {
        name: "Packers & Movers",
        url:  "./Images/packers_movers.png",
      },
  
  ];
  
  const sliderOneresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 6,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 6,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 2,
      numScroll: 1,
    },
  ];
  const sliderOneproductTemplate = (product) => {
    return (
      <div className="slider-one-card">
        
     
          <div className="circle-image">
            <div className='overlay'></div>
            <img src={product.url} alt="" />
          </div>
          <div className="simple-paragraph">{product.name}</div>
      
      </div>
    );
  };
  
  return (
    <>
          <Slider ProductService={sliderOneProductService} responsiveOptions={sliderOneresponsiveOptions} productTemplate={sliderOneproductTemplate} />
    </>
  )
}

export default SliderOne
