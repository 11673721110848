import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
const Footer = () => {

  const handleSubmit=(e)=>{
    e.preventDefault()
  }
  return (
    <>
    <div className='footer-container'>
        
            <div className='ps-3 footer-left-side'>
           <div className='row p-0 m-0'>
          <div className='col-lg-1 blank-div'></div>
            <div className='col-md-3 d-flex flex-column gap-3'><h5 className='m-0'>Our Company</h5>
            <Link to={''}>About SFTArea</Link>
            <Link to={''}>Our Services</Link>
            <Link to={''}>Meet Our Team</Link>
            <Link to={''}>Testimonials</Link>
            <Link to={''}>Career Opportunities</Link>
            <Link to={''}>SFTArea News</Link>
            </div>
            <div className='col-md-3  d-flex flex-column gap-3'><h5 className='mt-md-0 mt-sm-3 mt-3'>Buying & Selling</h5>
            <Link to={''}>Buy Property</Link>
            <Link to={''}>Sell Property</Link>
            <Link to={''}>Investment Opportunities</Link>
            <Link to={''}>Market Insights</Link>
          
            </div>
            <div className='col-md-2 d-flex flex-column gap-3'><h5 className='m-0'>Resources</h5>
            <Link to={''}>Buyer’s Guide</Link>
            <Link to={''}>Seller’s Guide</Link>
            <Link to={''}>FAQs</Link>
            <Link to={''}>Glossary of Terms</Link>
           
            </div>
            <div className='col-md-3 d-flex flex-column gap-3'><h5 className='m-0'>Legal</h5>
            <Link to={''}>Terms & Conditions</Link>
            <Link to={''}>Privacy Policy</Link>
            <Link to={''}>Legal Disclaimer</Link>
        
            </div>
           </div>
            </div>
            <div className='footer-right-side p-0'>
            <div className='footer-right-box'>

              <div className='top-head d-flex gap-3'>
                <div className='brand-image d-flex flex-column justify-content-center align-items-center'>
                  <img src="/Images/Brandicon.png" width={'63px'} alt="" />
                  <h4 className='mt-3'>SFT Area</h4>
                </div>
                <div className='connected-section mt-3 d-flex flex-column align-items-center'>
                  <p className='my-1'>Connect with us &nbsp;&nbsp; &nbsp; 1800 41 99099</p>
                  <p className='gold-para my-1'>Monday - Saturday</p>
                  <p>(9:00AM to 11:00PM ist)</p>

                </div>
              </div>
              <div className='social-media-div'>
              <Link> <img src="/Images/linkedin.png"  width={'40px'} alt="" /></Link>
              <Link> <img src="/Images/twitter.png"   width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/facebook.png"  width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/instagram.png" width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/p-logo.png"    width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/trend.png"     width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/youtube.png"   width={'50px'}   alt="" /></Link>
              
              </div>
              <form onSubmit={handleSubmit} className='footer-form'> 
<input type="text" placeholder='Enter Email' />
<div className='d-flex gap-1 justify-content-center'>
  <button className='simple-btn'>Feedback</button>
  <button className='white-btn'> Subscribe Now</button>
</div>
              </form>
            </div>
            </div>
           
        </div>
    
      
    </>
  )
}

export default Footer
