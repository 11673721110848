import React, { useEffect, useState } from 'react';
import './ImageSlider.css';
import { Link } from 'react-router-dom';

function ImageSlider({ imgs }) {
  const [wordData, setWordData] = useState(imgs[0]);
  const [val, setVal] = useState(0);

  const handleClick = (index) => {
    setVal(index);
    const wordSlider = imgs[index];
    setWordData(wordSlider);
  };

  useEffect(() => {
    console.log(imgs)
    const interval = setInterval(() => {
      setVal((prevVal) => (prevVal === imgs.length - 1 ? 0 : prevVal + 1));
      setWordData(imgs[val]);
    }, 3000);

    return () => clearInterval(interval);
  }, [val, imgs]);

  return (
    <div className="main">
      {imgs?.length ===3?<div className="flex_row d-flex gap-3 me-2">
        {imgs.map((data, i) => (
          <div className="thumbnail" key={i}>
            <img
              className={wordData.id === i ? 'clicked' : ''}
              src={data.value}
              onClick={() => handleClick(i)}
              height="100"
              width="170"
            />
            <p style={{ width: '165px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.group_name}
            </p>
          </div>
        ))}
      </div>:<>
      <div style={{height:"134px"}} className=''></div>
      </>}
      <div className="spotlight-container-slider-card">
        <div className="left-spotlight">
          <div className="signature-brand">
            <div className="brand-logo-signature">
              <img src={wordData.brand_logo} width={'60px'} alt="" />
            </div>
            <div className="brand-name">
              <h6 className="m-0 ">{wordData.name}</h6>
              <Link>View Projects</Link>
            </div>
          </div>
          <div className="brand-name d-flex flex-column gap-1">
            <h5 className="mb-0" style={{ width: '228px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {wordData.group_name}
            </h5>
            <p>{wordData.location}</p>
          </div>
          <div className="price-location d-flex flex-column gap-1">
            <h5 className="mb-0">{wordData.price}</h5>
            <p>{wordData.apartments}</p>
          </div>
          <button className="primary-btn">Contact</button>
        </div>
        <div className="right-spotlight">
          <img src={wordData.value} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
