import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import "./Slider.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const Slider = ({ProductService,responsiveOptions,productTemplate}) => {
  const [products, setProducts] = useState([]);

 
  useEffect(() => {
    setProducts(ProductService);
  }, []);

  
  return (
    <>
      <div className="slider-section">
        <Carousel
          value={products}
          numVisible={5}
          numScroll={1}
          containerClassName={"viewport-container"}
          contentClassName={"main-content"}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
          showIndicators={false}
          nextIcon={<FaArrowRightLong fontSize={'17'}/>}
          prevIcon={<FaArrowLeftLong fontSize={'17'} />}
        />
      </div>
    </>
  );
};

export default Slider;
