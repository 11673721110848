import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { IoMdCall } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { RiCloseFill } from "react-icons/ri";
import { GoTriangleRight } from "react-icons/go";
import { IoSearch } from "react-icons/io5";
import { FaListCheck } from "react-icons/fa6";
import { TiUser } from "react-icons/ti";
import { PiHeadsetFill } from "react-icons/pi";
import { MdCall, MdPhoneCallback } from "react-icons/md";
import { LuEye } from "react-icons/lu";
import { Sidebar } from "primereact/sidebar";
import {
  FaAngleDown,
  FaBars,
  FaCaretRight,
  FaChevronDown,
  FaChevronUp,
  FaUser,
  FaUserCircle,
} from "react-icons/fa";
import { FiAlignLeft, FiMenu } from "react-icons/fi";
import { HiMenuAlt1 } from "react-icons/hi";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        // Change 50 to the desired offset
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    transition: "background-color 0.3s",
    backgroundColor: scrolled ? "#808080" : "transparent", // Change colors as needed
    zIndex: 1000,
  };

  return (
    <>
      <div className="main-nav-container">
        <div className="top-navbar">
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center gap-2">
              {" "}
              <div className="brand-icon">
                <img src="/Images/Brandicon.png" height={"34px"} alt="" />
              </div>
              <div className="drop-location">
                Hyderabad <FaChevronDown size={18} className="ps-1 down-icon" />{" "}
                <FaChevronUp size={18} className="ps-1 up-icon" />
              </div>
            </div>
            <div className="d-flex gap-4 align-items-center">
              <Link to={""} className="nav-item">
                Premium Access
              </Link>
              <Link to={""} className="nav-item">
                {" "}
                Download App
              </Link>
              <button className="nav-post-btn d-flex justify-content-between">
                List Property <div className="free-span">Free</div>
              </button>
              <button className="contact-btn">
                <PiHeadsetFill />
                <div className="main-contact-card">
                  <div className="contact-card">
                    <h6 className="mb-0">CONTACT US</h6>
                    <div className="first-call call-details py-2 px-4">
                      <div className="phone-icon">
                        <MdCall size={20} />
                      </div>
                      <div className="">
                        <p>Toll Free | 9 AM - 8 PM IST</p>
                        <div className="number">1800-41-99099</div>
                      </div>
                    </div>
                    <div className="second-call d-flex align-items-center justify-content-between px-4">
                      <div className="call-details py-2 ">
                        <div className="phone-icon">
                          <MdCall size={20} />
                        </div>
                        <div className="">
                          <p>Toll Free | 9 AM - 8 PM IST</p>
                          <div className="number">1800-41-99099</div>
                        </div>
                      </div>
                      <div className="another-card-icon">
                        <FaCaretRight />
                      </div>
                      <div className="second-call-card py-4">
                        <div className="heading-card px-4">
                          International Numbers
                        </div>
                        <div className="first-call call-details py-2 px-4  mt-2">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                        <div className="first-call call-details py-2 px-4 ">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                        <div className="first-call call-details py-2 px-4 ">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 mt-3">
                      <button className="request-call-btn">
                        <MdPhoneCallback size={19} /> Request a Call Back
                      </button>
                    </div>
                    <div className="faq-text px-4 py-2">
                      To check all the FAQ <span>click here</span>
                    </div>
                  </div>
                </div>
              </button>
              {/* <div className="d-flex user-box align-items-center gap-1">
                {" "}
                <button className="user-btn">
                  <TiUser size={22} /> <span className="over-red"></span>{" "}
                </button>{" "}
                <FaChevronDown size={16} className="down-icon" />{" "}
                <FaChevronUp size={16} className="up-icon" />
                <div className="main-login-card">
                  <div className="login-card">
                    <h6>Login / Register</h6>
                    <div className="small-heading">My Activity</div>
                    <div className="d-flex ps-3 pt-2 flex-column gap-2">
                      <span className="text-small">Recently Searched</span>
                      <span className="text-small">Recently Viewed</span>
                      <span className="text-small">Shortlisted</span>
                      <span className="text-small">Contacted</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <button
                className="sidebar-btn"
                onClick={() => setVisibleRight(true)}
              >
                {" "}
                <FaBars size={18} />
                <img src="./Images/login-user-icon2.png" width={'25px'}/>
              </button>
            </div>
            <Sidebar
              visible={visibleRight}
              closeIcon={<RiCloseFill size={25} />}
              position="right"
              header={
                <div className="login-ragister-btn">
                  <FaUserCircle size={21} /> login / register
                </div>
              }
              onHide={() => setVisibleRight(false)}
            >
              <div className="sidebar-content-body pb-3 ps-3">
              <div   style={{ fontSize: "14px", fontWeight: "400" }} className="my-activity-div" >
                  My Activity
                </div>
           
                <div className="d-flex flex-column gap-2">
                <div className="simple-sidebar-btn">
                  {" "}
                  <img src={'./Images/eye-icon.svg'} alt="" />     Recently Viewed
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <img src={'./Images/search-icon.svg'} alt="" />Recently Searched
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <img src={'./Images/shortlisted-icon.svg'} alt="" />Shortlisted
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <img src={'./Images/call-icon.svg'} alt="" /> Contacted
                </div>
                </div>
                <div className="divider-down mt-2"></div>
                <div className="post-property-btn">
                  Post Property <span className="tag">FREE</span>
                </div>
                <div className="divider-down"></div>
                <div
                  style={{ fontSize: "14px", fontWeight: "400" }}
                  className="post-property-btn"
                >
                  Explore our Services
                </div>
                <div className="divider-down mb-3"></div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> For Buyers
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> For Tenants
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> For Owners
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> For Dealers / Builders
                </div>
                <div className="divider-down my-3"></div>
                <div className="simple-sidebar-btn ">
                  {" "}
                  <div className="me-3"> </div>Home Loans
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> Insights{" "}
                  <span className="new-tag"> NEW</span>
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> Articles & News
                </div>
                <div className="divider-down my-3"></div>
                <div className="simple-sidebar-btn ">
                  {" "}
                  <div className="me-3"> </div>About Us
                </div>
                <div className="simple-sidebar-btn">
                  {" "}
                  <GoTriangleRight size={18} /> Get Help{" "}
                </div>
                <div className="simple-sidebar-btn ">
                  {" "}
                  <div className="me-3"> </div>Download App
                </div>
              </div>
            </Sidebar>
          </div>
        </div>
        <div className="navbar-container" style={navbarStyle}>
          <div className="container  ">
            <div className="nav-bar">
              <NavLink
                to=""
                //    className={({ isActive }) =>{

                //      return ([
                //        isActive ? "testactive" : "nav-item",
                //       ].join(" "))
                //     }
                //  }
                className="nav-item"
              >
                Buy <FaAngleDown />
                <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"> <div className="dot"></div>  Flats</div>
                    <div className="items"><div className="dot"></div> Builder Floors</div>
                    <div className="items"><div className="dot"></div> Plots </div>
                    <div className="items"><div className="dot"></div> Apartments</div>
                    <div className="items"><div className="dot"></div> Farm</div>
                    <div className="items"><div className="dot"></div> Houses </div>
                    <div className="items"><div className="dot"></div> Studio </div>
                    <div className="items"><div className="dot"></div> Buy...</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Rent <FaAngleDown />{" "}
                <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Lorem Rent</div>
                    <div className="items"><div className="dot"></div> Lorem rent</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Sell <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"> <div className="dot"></div> Home</div>
                    <div className="items"> <div className="dot"></div> Building</div>
                    <div className="items"> <div className="dot"></div> Villa</div>
                    <div className="items"> <div className="dot"></div> Flates</div>
                    <div className="items"> <div className="dot"></div> Market</div>
                    <div className="items"> <div className="dot"></div> Others</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Commercial <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"> <div className="dot"></div> Commarcial Lorem </div>
                    <div className="items"> <div className="dot"></div> Building Rent</div>
                    <div className="items"> <div className="dot"></div> Buy Shop </div>
                    <div className="items"> <div className="dot"></div> Buy Home</div>
                    <div className="items"> <div className="dot"></div> Buy </div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Services <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Sell Property</div>
                    <div className="items"><div className="dot"></div> Sell Houses</div>
                    <div className="items"><div className="dot"></div> Rent </div>
                    <div className="items"><div className="dot"></div> Sell Plotes</div>
                    <div className="items"><div className="dot"></div> Sell Villas</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                More <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Advance booking</div>
                    <div className="items"><div className="dot"></div> Other Services</div>
                    <div className="items"><div className="dot"></div> All Flates</div>
                    <div className="items"><div className="dot"></div> Apartments Details</div>
                  
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Trends <FaAngleDown />{" "}
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Lorem Rent</div>
                    <div className="items"><div className="dot"></div> Lorem rent</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                    <div className="items"><div className="dot"></div> Buy Lorem</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Advertise <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Homes </div>
                    <div className="items"><div className="dot"></div> Advertise  </div>
                    <div className="items"><div className="dot"></div> Advertise  </div>
                    <div className="items"><div className="dot"></div> Advertise  </div>
                    <div className="items"><div className="dot"></div> Advertise  </div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Pages <FaAngleDown />
                 <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Page One</div>
                    <div className="items"><div className="dot"></div> Page Two</div>
                    <div className="items"><div className="dot"></div> Page Three</div>
                    <div className="items"><div className="dot"></div> Page Four</div>
                    <div className="items"><div className="dot"></div> Page Five</div>
                    <div className="items"><div className="dot"></div> Page Six</div>
                    <div className="items"><div className="dot"></div> Page Seven</div>
                  </div>
                </div>
              </NavLink>
              <NavLink to="" className="nav-item">
                Blog{" "}
              </NavLink>

              <div className="more-btn d-none">
                <FiMenu size={23} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
